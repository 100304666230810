import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  HostListener,
  OnDestroy,
  untracked,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CandidateStep,
  DISPLAYED_EXAM_STEPS,
  ExamStep,
  ExamSteps,
  examStepTranslation,
  ExamStepType,
  examStepWaitingMessage,
} from '@app/models/exam/examSteps.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { WaitingPageComponent } from '@components/common/waiting-page/waiting-page.component';
import { ExplanationComponent } from '@pages/candidate/explanation/explanation.component';
import { ResultsComponent } from '@pages/candidate/results/results.component';
import { TrainingCorrectionComponent } from '@pages/candidate/training-correction/training-correction.component';
import { CandidateExamSessionService } from '@services/candidateExamSession.service';
import { UserService } from '@services/user.service';
import { IS_PRODUCTION } from '@utils/constants';
import { stringInjector } from '@utils/stringHelper';
import { FormManager, PfFormWrapperComponent, PfStepsComponent } from 'pf-ui';
import { MenuItem } from 'primeng/api/menuitem';
import { CandidateQuestionsComponent } from './candidate-questions/candidate-questions.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PersonalInformationComponent,
    PfFormWrapperComponent,
    PfStepsComponent,
    ResultsComponent,
    WaitingPageComponent,
    ExplanationComponent,
    CandidateQuestionsComponent,
    TrainingCorrectionComponent,
    SpinnerComponent,
  ],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss',
})
export class CandidateComponent implements OnDestroy {
  // --------------------ACTION BLOCKS---------------------------------
  // Interception (clic droit)
  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    // Empêche le menu contextuel
    this.preventEventInProduction(event);
  }

  // Intercepter l'événement "dragstart" pour empêcher le drag
  @HostListener('document:dragstart', ['$event'])
  onDragStart(event: DragEvent): void {
    // Empêche l'opération de drag par défaut
    this.preventEventInProduction(event);
  }

  // Intercepter l'événement "dragover" pour empêcher le drag
  @HostListener('document:dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    // Empêche l'opération de drag par défaut
    this.preventEventInProduction(event);
  }

  // Intercepter l'événement "drop" pour empêcher le drop
  @HostListener('document:drop', ['$event'])
  onDrop(event: DragEvent): void {
    // Empêche le drop par défaut
    this.preventEventInProduction(event);
  }

  // Intercepter l'événement "beforeunload" pour afficher un message de confirmation avant la fermeture du navigateur
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeNavigatorClose(event: DragEvent): boolean {
    return this.preventEventInProduction(event);
  }

  // Site de référence:  https://defkey.com/
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): boolean {
    if (IS_PRODUCTION) {
      // Bloquer F12
      if (event.key === 'F12') {
        event.preventDefault();
        return false;
      }
      // On bloque tous les raccourcis CTRL + SHIFT
      if (event.ctrlKey && event.shiftKey) {
        event.preventDefault();
        return false;
      }
      // On bloque tous les raccourcis CTRL
      if (event.ctrlKey) {
        event.preventDefault();
        return false;
      }

      return true;
    }
    return true;
  }

  // -------------------------------------------------------------------

  examSession = this.examSessionService.signalExamSession;
  currentStep = this.candidateExamSessionService.getCurrentStep();
  isExamScreenDisplayed =
    this.candidateExamSessionService.isExamScreenDisplayed();
  userExamSession = this.userExamSessionService.signalUserExamSession;

  formManager: FormManager<ExamSteps>;
  stepperSteps: MenuItem[] = [];
  ExamStep = ExamStep;
  providerId: string;

  get steps(): CandidateStep[] {
    return Object.values(ExamStep).map((step: ExamStepType) => {
      const waitingMessage = examStepWaitingMessage?.[step];

      return {
        name: step,
        title: examStepTranslation[step]?.stepTitle || '',
        labelButton: '',
        waitingMessage:
          waitingMessage !== undefined
            ? stringInjector(
                waitingMessage,
                'exam_session_name',
                this.userExamSession()?.serie?.category?.name ?? '',
              )
            : undefined,
      };
    });
  }

  get currentStepPageTitle(): string {
    const currentStep = this.steps[this.formManager.currentStep];
    return examStepTranslation[currentStep.name]?.pageTitle;
  }

  constructor(
    private examSessionService: ExamSessionService,
    private userService: UserService,
    private userExamSessionService: UserExamSessionService,
    public candidateExamSessionService: CandidateExamSessionService,
    private authService: AuthService,
  ) {
    this.formManager = new FormManager();
    this.formManager.setSteps(this.steps);
    this.stepperSteps = this.filterStepperSteps();

    const providerIdAndSessionCode =
      this.authService.getProviderIdAndSessionCode();
    this.providerId = providerIdAndSessionCode.providerId;

    this.userService.getUser(this.providerId).subscribe();
    this.candidateExamSessionService.getGlobalInformations();

    // Listening CurrentStep
    effect(() => {
      const currentStep = this.currentStep();
      if (!currentStep) return; // TODO: manage error case
      this.formManager.goToStep(currentStep);
    });
  }

  filterStepperSteps = (): MenuItem[] => {
    const fullSteps = this.formManager.formatStepForStepper();
    return fullSteps.filter((step) => step.label);
  };

  isStepperDisplayed(): boolean {
    const currentStep = this.currentStep();
    if (!currentStep) return false; // TODO: manage error case
    return DISPLAYED_EXAM_STEPS.includes(currentStep);
  }

  goToTrainingWaitingExplanation = (): void => {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case

    // confirm session and clear session answers
    this.userExamSessionService.updateStatusConfirmed(
      examSession.id,
      this.providerId,
    );

    // first display waiting page
    this.candidateExamSessionService.updateCurrentStep(
      ExamStep.WAITING_TRAINING,
    );
  };

  getWaitingMessage = (): string | undefined => {
    const currentStep = this.steps[this.formManager.currentStep];
    return currentStep.waitingMessage;
  };

  goToTrainingStep(): void {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case
    // update DB first
    this.userExamSessionService
      .updateStatusFinishedExplanation(examSession.id, this.providerId)
      .subscribe({
        next: () => {
          this.candidateExamSessionService.updateCurrentStep(
            ExamStep.TRAINING_QUESTIONS,
          );
        },
      });
  }

  preventEventInProduction(event: MouseEvent | DragEvent): boolean {
    if (IS_PRODUCTION) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    untracked(() => {
      this.examSession.set(null);
      this.candidateExamSessionService.updateCurrentStep(null);
    });
    this.candidateExamSessionService.ngOnDestroy();
  }
}
