import { Routes } from '@angular/router';
import { CandidateComponent } from '@pages/candidate/candidate.component';
import { CandidateLoginComponent } from '@pages/candidate/login/login.component';
import { AdminRoute, CandidateRoute, RouteLabel } from '@utils/routes';
import { MenuItem } from 'primeng/api';
import { AuthGuard } from './guard/auth.guard';
import { CandidateAuthGuard } from './guard/candidate-auth.guard';
import {
  AgentDetail,
  hasAnyAuthorities,
} from '@models/user/agent-detail.model';

export const AdminRoutePermissions: Record<keyof typeof AdminRoute, string[]> =
  {
    Home: [],
    AnalyzedQuestion: [],
    AnalyzedQuestionId: [],
    Media: ['media.view'],
    Themes: ['theme.view'],
    Question: ['question.create', 'question.edit'],
    QuestionId: [],
    Questions: ['question.view'],
    Series: ['serie.view', 'archive.view'],
    SeriesGenerate: ['serie.view'],
    SeriesArchives: ['archive.view'],
    SeriesArchivesLogsUsers: [],
    SeriesArchivesDownloadedSeries: [],
    SeriesArchivesDownloadedSeriesId: [],
    SeriesId: [],
    Session: ['session.create'],
    SessionId: [],
    Sessions: ['session.view.all', 'session.view.external'],
    Settings: [],
    Users: ['agent.manage'],
    Business: ['business.manage'],
    Categories: ['category.view'],
    CategoryId: ['category.edit'],
    Tags: ['mediatag.view'],
    Statistics: ['stat.view'],
    StatsQuestions: [],
    StatsThemes: [],
    TrainingQuestions: [],
    StatsCategories: [],
    Archives: [],
  };

const unprotectedAdminRoutes: Routes = [
  {
    path: AdminRoute.Home,
    loadComponent: () =>
      import('./pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: AdminRoute.AnalyzedQuestionId,
    loadComponent: () =>
      import(
        './pages/home/analyzed-question/analyzed-questions.component'
      ).then((c) => c.AnalyzedQuestionsComponent),
  },
  {
    path: AdminRoute.Questions,
    loadComponent: () =>
      import('./pages/questions/questions.component').then(
        (c) => c.QuestionsComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Questions,
    },
  },
  {
    path: AdminRoute.Question,
    loadComponent: () =>
      import('./pages/questions/question/question.component').then(
        (c) => c.QuestionComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Questions,
    },
  },
  {
    path: AdminRoute.QuestionId,
    loadComponent: () =>
      import('./pages/questions/question/question.component').then(
        (c) => c.QuestionComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Questions,
    },
  },
  {
    path: AdminRoute.Media,
    loadComponent: () =>
      import('./pages/media/media.component').then((c) => c.MediaComponent),
    data: {
      permissions: AdminRoutePermissions.Media,
    },
  },
  {
    path: AdminRoute.Series,
    loadComponent: () =>
      import('./pages/series/series/series.component').then(
        (c) => c.SeriesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesGenerate,
    },
  },
  {
    path: AdminRoute.SeriesGenerate,
    loadComponent: () =>
      import('./pages/series/series/series.component').then(
        (c) => c.SeriesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesGenerate,
    },
  },
  {
    path: AdminRoute.SeriesArchives,
    loadComponent: () =>
      import('./pages/series/archives/archives.component').then(
        (c) => c.ArchivesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesArchives,
    },
  },
  {
    path: AdminRoute.SeriesArchivesLogsUsers,
    loadComponent: () =>
      import('./pages/series/archives/logs/logs-users.component').then(
        (c) => c.LogsUsersComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesArchives,
    },
  },
  {
    path: AdminRoute.SeriesArchivesDownloadedSeries,
    loadComponent: () =>
      import('./pages/series/archives/series/archives-series.component').then(
        (c) => c.ArchivesSeriesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesArchives,
    },
  },
  {
    path: AdminRoute.SeriesArchivesDownloadedSeriesId,
    loadComponent: () =>
      import('./pages/series/archives/series/serie/serie.component').then(
        (c) => c.SerieComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.SeriesArchives,
    },
  },
  {
    path: AdminRoute.SeriesId,
    loadComponent: () =>
      import(
        './pages/settings/business/categories/series/category-series.component'
      ).then((c) => c.CategorySeriesComponent),
    data: {
      permissions: AdminRoutePermissions.Business,
    },
  },
  {
    path: AdminRoute.SessionId,
    loadComponent: () =>
      import('./pages/sessions/session/session.component').then(
        (c) => c.SessionComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Sessions,
    },
  },
  {
    path: AdminRoute.Sessions,
    loadComponent: () =>
      import('./pages/sessions/sessions.component').then(
        (c) => c.SessionsComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Sessions,
    },
  },
  {
    path: AdminRoute.Session,
    loadComponent: () =>
      import('./pages/create-session/create-session.component').then(
        (c) => c.CreateSessionComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Session,
    },
  },
  {
    path: AdminRoute.Statistics,
    loadComponent: () =>
      import('./pages/statistics/statistics.component').then(
        (c) => c.StatisticsComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Statistics,
    },
  },
  {
    path: AdminRoute.Settings,
    loadComponent: () =>
      import('./pages/settings/business/business.component').then(
        (c) => c.BusinessComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Business,
    },
  },
  {
    path: AdminRoute.Users,
    loadComponent: () =>
      import('./pages/settings/users/users.component').then(
        (c) => c.UsersComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Users,
    },
  },
  {
    path: AdminRoute.Business,
    loadComponent: () =>
      import('./pages/settings/business/business.component').then(
        (c) => c.BusinessComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Business,
    },
  },
  {
    path: AdminRoute.TrainingQuestions,
    loadComponent: () =>
      import(
        './pages/settings/business/training-questions/training-questions.component'
      ).then((c) => c.TrainingQuestionsComponent),
    data: {
      permissions: AdminRoutePermissions.Business,
    },
  },
  {
    path: AdminRoute.Categories,
    loadComponent: () =>
      import('./pages/settings/business/categories/categories.component').then(
        (c) => c.CategoriesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Categories,
    },
  },
  {
    path: AdminRoute.CategoryId,
    loadComponent: () =>
      import(
        './pages/settings/business/categories/category-settings/category-settings.component'
      ).then((c) => c.CategorySettingsComponent),
    data: {
      permissions: AdminRoutePermissions.CategoryId,
    },
  },
  {
    path: AdminRoute.Themes,
    loadComponent: () =>
      import('./pages/settings/business/themes/themes.component').then(
        (c) => c.ThemesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Themes,
    },
  },
  {
    path: AdminRoute.StatsQuestions,
    loadComponent: () =>
      import('./pages/statistics/questions/questions.component').then(
        (c) => c.QuestionsComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Statistics,
    },
  },
  {
    path: AdminRoute.StatsThemes,
    loadComponent: () =>
      import('./pages/statistics/themes/themes.component').then(
        (c) => c.ThemesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Statistics,
    },
  },
  {
    path: AdminRoute.StatsCategories,
    loadComponent: () =>
      import('./pages/statistics/categories/categories.component').then(
        (c) => c.CategoriesComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Statistics,
    },
  },
  {
    path: AdminRoute.Tags,
    loadComponent: () =>
      import('./pages/settings/business/tags/tags.component').then(
        (c) => c.TagsComponent,
      ),
    data: {
      permissions: AdminRoutePermissions.Tags,
    },
  },
];

const adminRoutes = unprotectedAdminRoutes.map((unprotectedRoute) => ({
  ...unprotectedRoute,
  canActivate: [AuthGuard],
}));

export const adminMenuItems: MenuItem[] = [
  {
    label: RouteLabel.Home,
    icon: 'pi pi-table',
    routerLink: [AdminRoute.Home],
    routerLinkActiveOptions: { exact: true },
  },
];

export const questionsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: 'Questions',
    icon: 'pi pi-list',
    routerLink: [AdminRoute.Questions],
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Questions),
    items: [
      {
        label: RouteLabel.Question,
        icon: 'pi pi-plus',
        routerLink: [AdminRoute.Question],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.Question),
      },
      {
        label: RouteLabel.Questions,
        icon: 'pi pi-list',
        routerLink: [AdminRoute.Questions],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.Questions),
      },
      {
        label: RouteLabel.Media,
        icon: 'pi pi-images',
        routerLink: [AdminRoute.Media],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.Media),
      },
    ],
  };
};

export const seriesMenuItems = (agent: AgentDetail | null): MenuItem => {
  const defaultRoute = hasAnyAuthorities(
    agent,
    AdminRoutePermissions.SeriesGenerate,
  )
    ? [AdminRoute.Series]
    : [AdminRoute.SeriesArchives];

  return {
    label: 'Séries',
    icon: 'pi pi-file',
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Series),
    routerLink: defaultRoute,
    items: [
      {
        label: RouteLabel.SeriesGenerate,
        icon: 'pi pi-plus',
        routerLink: [AdminRoute.Series],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.SeriesGenerate),
      },
      {
        label: RouteLabel.SeriesArchives,
        icon: 'pi pi-inbox',
        routerLink: [AdminRoute.SeriesArchives],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.SeriesArchives),
      },
    ],
  };
};

export const sessionsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Sessions,
    icon: 'pi pi-calendar',
    styleClass: 'p-menuitem-link-active',
    routerLink: [AdminRoute.Sessions],
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Sessions),
    items: [
      {
        label: 'Programme des sessions',
        icon: 'pi pi-clock',
        routerLink: [AdminRoute.Sessions],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.Sessions),
      },
      {
        label: RouteLabel.Session,
        icon: 'pi pi-calendar-plus',
        routerLink: [AdminRoute.Session],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, AdminRoutePermissions.Session),
      },
    ],
  };
};

export const statisticsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Statistics,
    icon: 'pi pi-chart-bar',
    routerLink: [AdminRoute.Statistics],
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Statistics),
  };
};

export const agentManageMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Users,
    icon: 'pi pi-users',
    routerLink: [AdminRoute.Users],
    routerLinkActiveOptions: { exact: false },
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Users),
  };
};

export const businessManageMenuItems = (
  agent: AgentDetail | null,
): MenuItem => {
  return {
    label: RouteLabel.Business,
    icon: 'pi pi-briefcase',
    routerLink: [AdminRoute.Business],
    routerLinkActiveOptions: { exact: true },
    visible: hasAnyAuthorities(agent, AdminRoutePermissions.Business),
  };
};

export const settingsMenuItems = (agent: AgentDetail | null): MenuItem => {
  const defaultRoute = hasAnyAuthorities(agent, AdminRoutePermissions.Business)
    ? [AdminRoute.Settings]
    : [AdminRoute.Users];

  return {
    label: RouteLabel.Settings,
    icon: 'pi pi-cog',
    routerLink: defaultRoute,
    visible: hasAnyAuthorities(agent, ['business.manage', 'agent.manage']),
  };
};

export const routes: Routes = [
  ...adminRoutes,
  {
    path: CandidateRoute.Home,
    component: CandidateComponent,
    canActivate: [CandidateAuthGuard],
  },
  {
    path: CandidateRoute.Login,
    component: CandidateLoginComponent,
  },
  {
    path: 'admin',
    children: [{ path: '**', redirectTo: AdminRoute.Home, pathMatch: 'full' }],
  },
  { path: '**', redirectTo: CandidateRoute.Login, pathMatch: 'full' },
];
